<template>
  <!-- 添加/编辑分类页面 -->
  <div class="positionT0L0">
    <div v-show="$route.meta.isShow">
      <p class="title"><span></span> 添加分类</p>
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        labelAlign="left"
        :label-col="{ span: 2 }"
        :wrapper-col="{ span: 8 }"
      >
      
        <a-form-model-item label="分类名称" prop="name" class="por">
          <a-input v-model="form.name" :maxLength="32">
            <div slot="addonAfter">{{ form.name.length }}/32</div>
          </a-input>
        </a-form-model-item>

         <a-form-model-item label="排序" prop="name" class="por">
          <a-input v-model="form.sort" :maxLength="32">
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="所属首页分类" prop="categoryNo">
          <a-select
            show-search
            v-model="form.fCategoryNo"
            placeholder="所属首页分类"
            option-filter-prop="children"
            style="width: 200px"
            :getPopupContainer="
              (triggerNode) => triggerNode.parentNode || document.body
            "
            @change="categoryNoChange"
          >
            <a-select-option
              :value="item.labelNo"
              v-for="item in categoryNoArr"
              :key="item.labelNo"
            >
              {{ item.labelText }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      
      </a-form-model>

      <FooterToolBar :collapsed="sideCollapsed">
        <a-button class="margin_right60" @click="() => $router.go(-1)"
          >返回</a-button
        >
        <a-button
          type="primary"
          @click="keepClick"
          :loading="keepLoading"
          :disabled="keepLoading"
          >保存</a-button
        >
      </FooterToolBar>
    </div>
  </div>
</template>

<script>
import { baseMixin } from "@/store/app-mixin";
import { message } from "ant-design-vue";
import {
  GetCategoryApi,
  SaveCategoryApi,
  UpdCategoryApi,
  GetCategoryDetailApi,
} from "@/request/api/coursManage";

import moment from "moment";

export default {
  mixins: [baseMixin],
  created() {
    let id = this.$route.params.id;
    if (id !== "0") {
      this.GetCategoryDetail();
    } 
    // 分类列表
    this.teacherSelectFn();
  },
  data() {
    return {
      courseType: 1,
      categoryNoArr: [{ labelNo: "", labelText: "所属首页分类" }], // 所属首页分类
      keepLoading: false, // 保存的按钮是否加载
      isShowPointType: false, // 打点类型的提示文字是否显示
      form: {
        categoryNo: "",
        name: "",
        sort: "",
        fCategoryNo:""
      },
      rules: {
        name: [
          {
            required: true,
            message: "输入的内容不能为空",
            trigger: "blur",
          },
          {
            max: 32,
            message: "长度最大为32个",
            trigger: "blur",
          },
        ],
        fCategoryNo: [
          { required: true, message: "请选择所属首页分类", trigger: "change" },
        ]
      },
    };
  },
  computed: {
  },
  methods: {
    moment,
    // 分类改变的回调
    categoryNoChange(value) {
      this.form.fCategoryNo = value;
    },
    // 保存的按钮
    keepClick() {
      this.keepLoading = true;
      setTimeout(() => {
        this.keepLoading = false;
      }, 3000);
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
            if (this.$route.params.id != 0) {
              this.form.categoryNo = this.$route.params.id
              UpdCategoryApi(this.form).then(({ code }) => {
                if (code == 200) {
                  message.success("编辑成功");
                  // this.$router.go(-1);
                  location.href = `/coursManage/courseCategory`;
                }
              });
            } else {
              SaveCategoryApi(this.form).then(({ code }) => {
                if (code == 200) {
                  message.success("新增成功");
                  location.href = `/coursManage/courseCategory`;
                }
              });
            }
        } else {
          message.error("还有必填项未完成");
        }
      });
    },
    // 封装获取老师列表的函数
    teacherSelectFn() {
      GetCategoryApi().then(({ code, data }) => {
        if (code == 200) {
          this.categoryNoArr = data;
        }
      });
    },
    GetCategoryDetail() {
      let id = this.$route.params.id;
      GetCategoryDetailApi({ categoryNo: id }).then(({ code, data }) => {
        if (code == 200) {
         this.form.categoryNo = data.categoryNo
         this.form.name = data.Name
         this.form.fCategoryNo = data.CateNo
         this.form.sort = data.CateOrder
        }
      });
    }
  },
};
</script>
 
<style lang="less" scoped>
/deep/textarea.ant-input {
  resize: none;
}
/deep/.description .ant-form-item-control {
  line-height: unset;
}
/deep/.ant-upload-list-item-name {
  height: 42px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: all linear 0.2s;
}
/deep/.ant-upload-list-picture .ant-upload-list-item-name,
 .ant-upload-list-picture-card .ant-upload-list-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: all linear 0.2s;
}
/deep/.ant-upload-text {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  transition: all linear 0.2s;
}
</style>